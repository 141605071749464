import React, { useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useState } from 'react';
import { setFlightType } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import { post } from '../../Utilities/api';
import DestinationPickerForm from './DestinationPickerForm';
import { FadeLoader } from 'react-spinners';
import DestinationPickerResults from './DestinationPickerResults';
import { ReactComponent as ButtonIcon } from '../../images/noun-where-to-go-2.svg';

import css from '@emotion/css';

const override = css`
	display: block;
	margin: 3rem auto;
`;

const DestinationPicker = ({}) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [results, setResults] = useState([]);
	const [destinationSearchInfo, setDestinationSearchInfo] = useState({
		relativeLocation: null,
		geographicalType: [],
		activityTypes: [],
		groupType: null,
		results: [],
	});

	const windows = [
		{
			dataName: 'relativeLocation',
			header: 'Where are you trying to go?',
			description: '',
			options: ['Domestic', 'International'],
			selectMany: false,
		},
		{
			dataName: 'groupType',
			header: 'Who is coming with you?',
			description: '',
			options: ['Family Friendly', 'Adults Only'],
			selectMany: false,
		},
		{
			dataName: 'geographicalType',
			header: 'Where are you trying to go?',
			description: '',
			options: ['Beach', 'City', 'Mountains', 'Countryside/Wineries'],
			selectMany: true,
		},
		{
			dataName: 'activityTypes',
			header: 'Which of these activities are you looking for out of a trip?',
			description: '',
			options: [
				'Spa',
				'Skiing/Snowsports',
				'Shopping',
				'Nature',
				'History',
				'Art',
			],
			selectMany: true,
		},
	];

	// const submit = async (e) => {
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	const res = await post(
	// 		'/api/v1/data/private/suggested-destinations/suggested',
	// 		destinationSearchInfo,
	// 		true
	// 	);
	// 	if (res.status === 200) {
	// 		setDestinationSearchInfo({
	// 			...destinationSearchInfo,
	// 			results: res.data.suggestedDestinations,
	// 		});
	// 		setLoading(false);
	// 	}
	// };

	// const handleSelected = (selected, currentValue) => {
	// 	if (Array.isArray(selected) && selected.includes(currentValue)) {
	// 		return true;
	// 	}
	// 	if (selected === currentValue) {
	// 		return true;
	// 	}
	// 	return false;
	// };

	// const handleSelectClick = (currentField, currentValue) => {
	// 	const selected = destinationSearchInfo[currentField];
	// 	if (Array.isArray(selected)) {
	// 		if (!selected.includes(currentValue)) {
	// 			setDestinationSearchInfo({
	// 				...destinationSearchInfo,
	// 				[currentField]: [...selected, currentValue],
	// 			});
	// 		} else {
	// 			setDestinationSearchInfo({
	// 				...destinationSearchInfo,
	// 				[currentField]: selected.filter((value) => currentValue !== value),
	// 			});
	// 		}
	// 	} else {
	// 		setDestinationSearchInfo({
	// 			...destinationSearchInfo,
	// 			[currentField]: currentValue,
	// 		});
	// 	}
	// };
	const restart = () => {
		setDestinationSearchInfo({
			relativeLocation: null,
			geographicalType: [],
			activityTypes: [],
			groupType: null,
			results: [],
		});
		setSubmitted(false);
		setResults([]);
	};

	useEffect(() => {
		return restart;
	}, []);

	return (
		<Popup
			open={open}
			trigger={
				<button
					className='homepage__subnav-button'
					onClick={() => setOpen(true)}>
					Inspiration <ButtonIcon />
				</button>
			}
			modal>
			{(close) => (
				<div className='popup destination-picker-popup' id='popup'>
					<div className='popup__content'>
						<button
							className='popup__close'
							onClick={(e) => {
								e.preventDefault();
								restart();
								close();
							}}>
							<i className='fa fa-times'></i>
						</button>
						{!loading ? (
							<>
								{submitted ? (
									<>
										<h3 className='result__header'>Locations</h3>
										<DestinationPickerResults
											destination
											results={results.suggestedDestinations}
										/>
										<h3 className='result__header'>Trips</h3>
										<DestinationPickerResults
											results={results.suggestedTestimonials}
											restart={restart}
											showButtons
										/>
									</>
								) : (
									<DestinationPickerForm
										destinationSearchInfo={destinationSearchInfo}
										setDestinationSearchInfo={setDestinationSearchInfo}
										setResults={setResults}
										setLoading={setLoading}
										setSubmitted={setSubmitted}
									/>
								)}
							</>
						) : (
							<FadeLoader css={override} />
						)}
					</div>
				</div>
			)}
		</Popup>
	);
};

export default DestinationPicker;
