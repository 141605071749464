import React, { useEffect, useRef, useState } from 'react';
import useOnScreen from '../../hooks/useOnScreen';
import url from '../../video/how-rove-works.mp4';

const PlanTripVideoMain = () => {
	const [hasBeenPlayed, setHasBeenPlayed] = useState(false);
	const ref = useRef(null);
	const isVisible = useOnScreen(ref);

	// useEffect(() => {
	// 	if (isVisible && !hasBeenPlayed) {
	// 		ref.current.play();
	// 		setHasBeenPlayed(true);
	// 	}
	// }, [isVisible]);
	return (
		<div className='how-it-works__video--background'>
			<video ref={ref} className='how-it-works__video' controls>
				<source src={url} type='video/mp4' />
			</video>
		</div>
	);
};

export default PlanTripVideoMain;
