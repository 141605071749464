import React, { Fragment, useState } from 'react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import AirportAutofill from './AirportAutofill';
import { clearFlightError } from '../../redux/actions/errors';
import { editFlightDetail } from '../../redux/actions/trip';
import { capitalize } from '../../Utilities/helperFunctions';
import { useEffect } from 'react';
import { useLayoutEffect } from 'react';

const airportData = require('../../Utilities/new_airport_codes.json');

const AutofillSection = ({
	searchResults,
	returnSearchResults,
	heightRefs,
	isFocused,
	// name,
	// setSearchResults,
	// handleHotel,

	// multiCity,
	// inputKey,
	// flightDetails,

	// detailIndex,
	// setTripInputs,
	// tripInputs,
}) => {
	const [sectionHeight, setSectionHeight] = useState(null);
	const returnAirportInfo = (string) => {
		const foundItem =
			airportData && airportData.length
				? airportData.find((item) => item.iata === string)
				: null;
		if (foundItem) {
			const airport = foundItem.airportName;
			return `${capitalize(airport)} - ${string.toUpperCase()}`;
		} else return string;
	};

	// const returnSearchResults = () => {
	// 	return searchResults && searchResults.length > 0
	// 		? searchResults.map((result, index) => {
	// 				return (
	// 					<Fragment key={`${inputKey}-index-${index}`}>
	// 						<AirportAutofill
	// 							heightRefs={heightRefs}
	// 							index={index}
	// 							stateCode={result.stateCode}
	// 							name={result.airportName}
	// 							iataCode={result.iata.toUpperCase()}
	// 							onClick={() => {
	// 								const hotelItem = name === 'destination' ? result : null;
	// 								multiCity &&
	// 									name === 'destination' &&
	// 									flightDetails[detailIndex + 1] &&
	// 									editFlightDetail(detailIndex + 1, 'departure', result.iata);
	// 								name === 'destination' &&
	// 									editFlightDetail(detailIndex, 'hotelItem', result);
	// 								editFlightDetail(detailIndex, name, result.iata);
	// 								clearFlightError(detailIndex, name);
	// 								setTripInputs({
	// 									...tripInputs,
	// 									[name]: returnAirportInfo(result.iata),
	// 								});
	// 								setSearchResults([]);
	// 								handleHotel &&
	// 									handleHotel({ [name]: result.iata, hotelItem });
	// 							}}
	// 						/>
	// 					</Fragment>
	// 				);
	// 		  })
	// 		: null;
	// };

	useLayoutEffect(() => {
		if (searchResults.length && heightRefs.current.length) {
			setSectionHeight(
				heightRefs.current.reduce((acc, item) => {
					return item ? item.offsetHeight + acc : acc;
				}, 0)
			);
		} else {
			setSectionHeight(0);
		}
	}, [searchResults, heightRefs]);
	return (
		<div
			style={{ height: sectionHeight - 1 + 'px' }}
			className={`autofill-section${
				searchResults.length && isFocused ? ' visible' : ' invisible'
			}`}>
			{returnSearchResults()}
		</div>
	);
};

export default connect(null, { clearFlightError, editFlightDetail })(
	AutofillSection
);
