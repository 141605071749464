import React, { useState } from 'react';
import woman from '../../images/Woman.png';
import sailing from '../../images/sailing.jpg';
import kayaking from '../../images/kayaking.png';
import arrow from '../../images/noun_Arrow_1256499.svg';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// components
import HowItWorks from '../landing/HowItWorks';
import ServiceSection from '../landing/ServiceSection';
import { Fragment } from 'react';
import redirectHOC from '../hoc/RedirectHOC';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import WhySection from '../landing/WhySection';
import LandingSubheader from '../landing/LandingSubheader';
import ReactGA from 'react-ga';
import { get } from '../../Utilities/api';
import TestimonialShow from '../TestimonialShow';
import { AnimatePresence } from 'framer-motion';

const HomePage = ({ start }) => {
	const { token } = useParams();
	const [currentHighlights, setCurrentHighlights] = useState([]);
	const [allHighlights, setAllHighlights] = useState([]);
	const [startingHighlight, setStartingHighlight] = useState(0);
	const [focusedHighlight, setFocusedHighlight] = useState(null);

	const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
	ReactGA.initialize(TRACKING_ID);
	ReactGA.event({
		category: 'Page_Events',
		action: 'page_enter',
		label: 'home_page',
	});

	const getNewHightlights = async () => {
		try {
			const res = await get('/api/v1/data/testimonial/get-highlights');
			setAllHighlights(res.data.foundTestimonials);
			setCurrentHighlights(res.data.foundTestimonials.slice(0, 3));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		localStorage.setItem('resetPasswordToken', token);
		getNewHightlights();
	}, []);
	return (
		<Fragment>
			<div className='home-page-container' id='home-page__container'>
				<AnimatePresence initial={true} mode='wait'>
					{focusedHighlight ? (
						<TestimonialShow
							{...focusedHighlight}
							closeModal={() => setFocusedHighlight(null)}
						/>
					) : null}
				</AnimatePresence>

				<img
					className='landing-photo__landscape'
					src={kayaking}
					alt='landscape'
				/>
				{/* <div style={{ display: 'flex', justifyContent: 'space-between' }} /> */}

				<LandingSubheader />
				<HowItWorks />

				<img
					className='landing-photo__landscape landscape__2'
					src={woman}
					alt=''
				/>

				<WhySection start={start} token={token} />
				<h3 className='testimonial-title'>Testimonials</h3>
				<div className='testimonial-highlights'>
					{currentHighlights.length
						? currentHighlights.map((highlight) => {
								return (
									<button
										className='highlight-button highlight'
										key={highlight._id}
										onClick={async (e) => {
											e.preventDefault();
											const res = await get(
												`/api/v1/data/testimonial/${highlight.groupId._id}`,
												{},
												false
											);
											setFocusedHighlight({
												testimonial: res.data.foundGroup,
												focusedHighlight: highlight,
											});
										}}>
										{' '}
										<img src={highlight.groupId?.photos[0]} alt='' />
										<p>
											<span>{highlight.feedback}</span>
										</p>
									</button>
								);
						  })
						: null}
				</div>
				<h3 className='testimonial-title'>
					<a href='/testimonials'>See More</a>
				</h3>
				<img className='landing-photo__landscape' src={sailing} alt='' />

				<ServiceSection />
			</div>
		</Fragment>
	);
};

HomePage.propTypes = {
	token: PropTypes.string,
	newRegister: PropTypes.bool,
};
const mapStateToProps = (state) => ({
	token: state.auth.token,
	newRegister: state.auth.newRegister,
	user: state.auth.user,
	pathname: state.redirect.pathname,
});

export default connect(mapStateToProps, null)(redirectHOC(HomePage));
