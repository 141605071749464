import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import buttonIcon from '../../images/noun-cog.svg';
import HowItWorksVideo from '../landing/HowItWorksVideo';
import PlanTripVideoMain from './PlanTripVideoMain';

const PlanTripVideo = ({ defaultOpen }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (defaultOpen) {
			setOpen(true);
		}
	}, [defaultOpen]);
	return (
		<Popup
			open={open}
			trigger={
				<button
					className='trip-profile__subnav-button'
					onClick={() => setOpen(true)}>
					{' '}
					<img src={buttonIcon} alt='' />
				</button>
			}
			modal>
			{(close) => (
				<div className='popup plan-trip-popup' id='popup'>
					<div className='popup__content padding-0'>
						<button className='popup__close' onClick={close}>
							<i className='fa fa-times'></i>
						</button>

						<PlanTripVideoMain />
					</div>
				</div>
			)}
		</Popup>
	);
};

export default PlanTripVideo;
