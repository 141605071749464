import React, { Fragment } from 'react';
import WhyImageDiv from '../landing/WhyImageDiv';
import WhyList from '../landing/WhyList';
import LandingPopup from '../landing/LandingPopup';
import WhatYouGet from './WhatYouGet';
import HowItWorksVideo from './HowItWorksVideo';

const WhySection = ({ start, token }) => {
	return (
		<section className='why-section'>
			<div className='why-section--left'>
				<HowItWorksVideo />
				<WhyImageDiv />
			</div>
			<div className='why-section--right'>
				<WhyList />
				<WhatYouGet />
			</div>
		</section>
	);
};

export default WhySection;
