import React from 'react';
import PropTypes from 'prop-types';
import WYGItem from './WYGItem';
import config from '../../config/config';
import WYGButton from './WYGButton';
import Popup from 'reactjs-popup';
import { useState } from 'react';
import FlightToggler from '../CreateTrip/flights/FlightToggler';
import { setFlightType } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import trip from '../../redux/reducers/trip';
import { ReactComponent as ButtonIcon } from '../../images/noun-what-you-get.svg';

const WhatYouGet = ({ setFlightType }) => {
	const [open, setOpen] = useState(false);
	const [isMultiCity, setIsMultiCity] = useState(false);

	const { flights, lodging, activities, transportation } = config.WYG;

	const setItemNumber = (text, number, { activity, transport, lodging }) => {
		// activity &&
		let splitText = text.split('-');
		let result = `${splitText[0]} ${number} ${splitText[1]}`;
		if (activity) {
			result = `${result}${
				isMultiCity
					? ' ' + 'per city' + ' ' + splitText[2]
					: !isMultiCity
					? splitText[2]
					: ''
			}`;
		}
		if (transport || lodging) {
			const cityOrTrip = isMultiCity ? 'city' : 'trip';
			result = result + cityOrTrip + splitText[2];
		}

		return result;
	};

	const returnWYGItem = ({
		description,
		icon,
		roundIconAmount,
		multiIconAmount,
		title,
	}) => {
		let amount = isMultiCity ? multiIconAmount : roundIconAmount;
		let newDescription = setItemNumber(description, amount, {
			activity: title === 'Activities' ? true : false,
			transport: title === 'Transportation' ? true : false,
			lodging: title === 'Lodging' ? true : false,
		});

		let newTitle = `${amount} ${title}`;
		return (
			<WYGItem
				title={newTitle}
				description={newDescription}
				icon={icon}
				// roundIconAmount={roundIconAmount}
				// multiIconAmount={multiIconAmount}
			/>
		);
	};

	const leftClick = () => {
		setIsMultiCity(false);
		// setFlightType('round-trip');
	};

	const rightClick = () => {
		setIsMultiCity(true);
		// setFlightType('multi-city');
		// clearFlightErrors();
	};

	return (
		<Popup
			open={open}
			trigger={
				<button
					className='homepage__subnav-button'
					onClick={() => setOpen(true)}>
					{' '}
					What You Get
					<ButtonIcon />
				</button>
			}
			modal>
			{(close) => (
				<div className='popup wyg-popup' id='popup'>
					<div className='popup__content'>
						<button className='popup__close' onClick={close}>
							<i className='fa fa-times'></i>
						</button>

						<div className='what-you-get'>
							<FlightToggler
								cName={'what-you-get__toggler'}
								leftTitle={'Round Trip'}
								rightTitle={'Multi-City Trip'}
								leftValue={'roundTrip'}
								rightValue={'multiCityTrip'}
								leftSelected={!isMultiCity}
								rightSelected={isMultiCity}
								leftClick={leftClick}
								rightClick={rightClick}
								errors={null}
							/>
							<div className='what-you-get__main'>
								{returnWYGItem(flights)}
								{returnWYGItem(lodging)}
								{returnWYGItem(activities)}
								{returnWYGItem(transportation)}
							</div>
						</div>
					</div>
				</div>
			)}
		</Popup>
	);
};

WhatYouGet.propTypes = {};

export default connect(null, { setFlightType })(WhatYouGet);
