import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { ReactComponent as ButtonIcon } from '../../images/noun-faq.svg';
import buttonIcon from '../../images/noun-faq.svg';
import CalendlyMain from './CalendlyMain';
const CalendlyModal = () => {
	const [open, setOpen] = useState(false);
	return (
		<Popup
			open={open}
			trigger={
				<button
					className='homepage__subnav-button'
					onClick={() => setOpen(true)}>
					{' '}
					Talk To Someone
					<ButtonIcon />
				</button>
			}
			modal>
			{(close) => (
				<div className='popup wyg-popup' id='popup'>
					<div className='popup__content padding-0'>
						<button className='popup__close' onClick={close}>
							<i className='fa fa-times'></i>
						</button>

						<CalendlyMain />
					</div>
				</div>
			)}
		</Popup>
	);
};

export default CalendlyModal;
