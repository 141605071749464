import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const CityStateSelector = ({ cities, selectedCity, setSelectedCity }) => {
	const [showCities, setShowCities] = useState(false);
	const mapCities = () => {
		return cities && cities.length
			? cities.map((city, index) => {
					return (
						<React.Fragment key={`hotel-city-${index}`}>
							<button
								className='btn'
								style={{
									width: '24rem',
									backgroundColor: city === selectedCity ? 'grey' : 'white',
									color: city === selectedCity ? 'white' : 'black',
								}}
								onClick={() => {
									setSelectedCity(city);
									setShowCities(!showCities);
								}}>
								{city}
							</button>
						</React.Fragment>
					);
			  })
			: null;
	};
	return (
		<div className='newcss-city-state-selector-container'>
			{selectedCity ? (
				<button
					className='btn'
					style={{ position: 'relative' }}
					onClick={() => setShowCities(!showCities)}>
					{selectedCity}
					<IoIosArrowDown style={{ right: 20, position: 'absolute' }} />
				</button>
			) : (
				<button
					className='btn'
					style={{ position: 'relative' }}
					onClick={() => setShowCities(!showCities)}>
					Select a City to view
					<IoIosArrowDown style={{ right: 20, position: 'absolute' }} />
				</button>
			)}
			<div className='dropdown'>{showCities ? mapCities() : null}</div>
		</div>
	);
};

export default CityStateSelector;
