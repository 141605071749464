import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removeRedirect } from '../../redux/actions/redirect';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/core';
import { getAuthUser, loadAuthUser, verifyMe } from '../../redux/actions/auth';
import { Fragment } from 'react';
import Alert from '../Layout/Alert';
import requireAuth from '../hoc/AuthComponent';
import TripCards from '../Trip/TripCards';
import AddNewTrip from '../Trip/AddNewTrip';
import { clearTrip, submitUpdate } from '../../redux/actions/trip';
import { useParams } from 'react-router-dom';
import { setUserProfile, getUserProfile } from '../../redux/actions/profile';
import WYGCard from '../Trip/WYGCard';
import DestinationPickerCard from '../Trip/DestinationPickerCard';
import FaqModal from '../faq/FaqModal';
import back from '../../images/noun-arrow-1256499-FFFFFF.svg';
import CalendlyModal from '../calendly/CalendlyModal';

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
	top: 0;
	left: 0;
`;

const Trip = ({
	authUser,
	location,
	getAuthUser,
	trip,
	submitUpdate,
	userProfile,
	isAdmin,
	getUserProfile,
	setUserProfile,
}) => {
	const [alert, setAlert] = useState(false);
	const [tripSaveLoading, setTripSaveLoading] = useState(true);
	const [userGetLoading, setUserGetLoading] = useState(true);
	const [window, setWindow] = useState('main');
	let { userId } = useParams();
	const returnLoader = () => {
		return (
			<div className='newcss-sweet-loading trip-dash newcss-80vh'>
				<FadeLoader css={override} size={150} color={'black'} loading={true} />
			</div>
		);
	};

	const handlegetAuthUser = async () => {
		const res = await getAuthUser();
		if (res.success) {
			userGetLoading && setUserGetLoading(false);
			tripSaveLoading && setTripSaveLoading(false);
			console.log('is admin', isAdmin);
			console.log('userId', userId);
			if (userId) {
				getUserProfile(userId);
			} else {
				setUserProfile(res.authUser);
			}
		}
	};

	const handleWindow = () => {
		switch (window) {
			case 'main':
				return (
					<nav className='homepage__subnav'>
						<ul>
							<li className='w-100'>
								<button
									onClick={() => {
										console.log('clicking');
										setWindow('trips');
									}}
									className='homepage__subnav-button'>
									My Trips
								</button>
							</li>
							<li>
								<AddNewTrip />
							</li>
							<li>
								<WYGCard />
							</li>
							<li>
								<DestinationPickerCard />
							</li>
							<li>
								<FaqModal />
							</li>
							<li>
								<CalendlyModal />
							</li>
						</ul>
					</nav>
				);
			case 'trips':
				const { trips } = userProfile;
				return (
					<div className='newcss-grid-2-big-gap'>
						<button className='back' onClick={() => setWindow('main')}>
							<img src={back} alt='' />
						</button>
						<TripCards trips={trips} />
					</div>
				);

			default:
				return null;
		}
	};

	useEffect(() => {
		(async () => await handlegetAuthUser())();
	}, [isAdmin]);

	useEffect(() => {
		if (location.state && location.state.alert) {
			setAlert(true);
		}
	}, [location]);

	// useEffect(() => {
	// 	getAuthUser();
	// }, []);

	const returnDashboard = () => {
		const { trips } = userProfile;
		return (
			<div style={{ textAlign: 'center' }} className='newcss-homepage-center'>
				{alert === true && (
					<Alert
						text={location.state.alert}
						cname='success'
						removeAlert={() => setAlert(false)}
					/>
				)}
				{handleWindow()}

				{/* <nav className='homepage__subnav'>
					<ul>
						<li className='w-100'>
							<button className='homepage__subnav-button'>My Trips</button>
						</li>
						<li>
							<AddNewTrip />
						</li>
						<li>
							<WYGCard />
						</li>
						<li>
							<DestinationPickerCard />
						</li>
						<li>
							<FaqModal />
						</li>
					</ul>
				</nav> */}
				{/* <div className='newcss-grid-2-big-gap'>
					<TripCards trips={trips} />
				</div> */}
			</div>
		);
	};

	return (
		<Fragment>
			{!userProfile || tripSaveLoading || userGetLoading
				? returnLoader()
				: returnDashboard()}

			{/* {returnLoader()} */}
		</Fragment>
	);
};

Trip.propTypes = {
	user: PropTypes.object,
	loading: PropTypes.bool,
	loadAuthUser: PropTypes.func.isRequired,
	// clearTrip: PropTypes.func.isRequired,
	removeRedirect: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	loading: state.auth.loading,
	auth: state.auth,
	trip: state.trip,
});

export default connect(mapStateToProps, {
	loadAuthUser,
	removeRedirect,
	getAuthUser,
	verifyMe,
	clearTrip,
	submitUpdate,
	setUserProfile,
	getUserProfile,
})(requireAuth(Trip));
