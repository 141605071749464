import React, { useEffect } from 'react';

const CalendlyMain = () => {
	useEffect(() => {
		const head = document.querySelector('head');
		const script = document.createElement('script');
		script.setAttribute(
			'src',
			'https://assets.calendly.com/assets/external/widget.js'
		);
		head.appendChild(script);
	}, []);
	return (
		<div
			className='calendly-inline-widget'
			data-url='https://calendly.com/carolynn-v3e/consultation-with-rove?hide_gdpr_banner=1'
			style={{ minWidth: '60rem', height: '55rem' }}></div>
	);
};

export default CalendlyMain;
