import React from 'react';
import { motion } from 'framer-motion';

const FaqDirectory = ({ currentOptions, setCurrent }) => {
	return (
		<motion.section
			initial={{ x: '-100%', opacity: 0 }}
			animate={{ transition: '100ms', x: 0, opacity: 1 }}
			transition={{ x: { type: 'spring', bounce: 0 } }}
			exit={{ x: '-100%', opacity: 0 }}
			className='destination-picker-card'>
			{currentOptions.map((buttonKey) => {
				return (
					<button
						key={buttonKey}
						onClick={() => {
							setCurrent(buttonKey);
						}}
						className='destination-picker-button'>
						{buttonKey}
					</button>
				);
			})}
		</motion.section>
	);
};

export default FaqDirectory;
