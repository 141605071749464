import React, { Fragment } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { connect } from 'react-redux';
import ModalImage from './ModalImage';
import carDoorIcon from '../../images/noun-car-door-1643574-142350.svg';
import carSeatIcon from '../../images/noun-seat-1027004-142350.svg';
import baggageIcon from '../../images/noun-suitcase-1560743-142350.svg';
import SelectedButton from './selectedModal/SelectedButton';
import ItemDetails from './selectedModal/ItemDetails';
import ItemOptions from './selectedModal/ItemOptions';
import { removeItemFrom, addItemTo } from '../../redux/actions/trip';
import FlightInfo from './selectedModal/FlightInfo';
import { deselectModalItem } from '../../redux/actions/itineraryModal';
import noImage from '../../images/noun_Camera_prohibited_1554484.svg';
import datetimeDifference from 'datetime-difference';
import { useState } from 'react';
import CustomEventForm from '../ItemsOptions/CustomEventForm';
import {
	returnItemFields,
	returnKey,
	toDollarStyle,
	useCheckItemStatus,
	useCheckType,
} from '../../Utilities/helperFunctions';
import { handleImage } from '../../redux/reducers/helperFunctions';

import { sanitizeCarData } from '../../Utilities/carHelpers';

const variants = {
	open: { x: '0%', display: 'block' },
	closed: {
		x: '100%',
		display: 'none',
	},
};

const ItineraryModal = ({
	itineraryModalOpen,
	selectedItem,
	deselectModalItem,
	addItemTo,
	removeItemFrom,
	trip,
}) => {
	const { activity, flight, lodging, transportation, custom, amadeusCars } =
		useCheckType(
			(selectedItem && (selectedItem.type || selectedItem.data.type)) || null
		);

	const key = returnKey({
		activity,
		flight,
		lodging,
		transportation,
		custom,
		amadeusCars,
	});
	const { itemPaid, itemAuthorized, itemSelected, itemInCart } =
		useCheckItemStatus(trip, selectedItem);
	const currentGroup = itemPaid || itemAuthorized || itemSelected || itemInCart;
	const [eventTime, setEventTime] = useState({
		start: (selectedItem && selectedItem.start) || null,
		end: (selectedItem && selectedItem.end) || null,
	});

	const handleItemImage = () => {
		let image;
		if (lodging) {
			const { descriptive_info } = returnItemFields(selectedItem.data, [
				'HotelCode',
				'RoomRates',
				'descriptive_info',
				'HotelName',
			]);

			const { HotelInfo } = returnItemFields(descriptive_info, ['HotelInfo']);
			const { Descriptions } = returnItemFields(HotelInfo, ['Descriptions']);
			const { MultimediaDescription } = returnItemFields(Descriptions, [
				'MultimediaDescription',
			]);

			image = handleImage(MultimediaDescription, 450, 600);
		}

		if (amadeusCars) {
			let { sizedPictures } = selectedItem.data;
			image =
				sizedPictures &&
				sizedPictures.length &&
				sizedPictures[0].imageURL &&
				sizedPictures[0].imageURL.address &&
				sizedPictures[0].imageURL.address.identifier
					? sizedPictures[0].imageURL.address.identifier
					: null;
		}
		if (selectedItem.imageUrl) {
			image = selectedItem.imageUrl;
		}
		return image;
	};

	const handleRating = () => {
		if (lodging) {
			const rating =
				selectedItem &&
				selectedItem.data &&
				selectedItem.data.descriptive_info &&
				selectedItem.data.descriptive_info['AffiliationInfo'] &&
				selectedItem.data.descriptive_info['AffiliationInfo'].length &&
				selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards'] &&
				selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards']
					.length &&
				selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards'][0][
					'Award'
				] &&
				selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards'][0][
					'Award'
				].length &&
				selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards'][0][
					'Award'
				][0]['info'] &&
				selectedItem.data.descriptive_info['AffiliationInfo'][0]['Awards'][0][
					'Award'
				][0]['info'].Rating
					? selectedItem.data.descriptive_info['AffiliationInfo'][0][
							'Awards'
					  ][0]['Award'][0]['info'].Rating
					: null;
			return rating;
		}
		return selectedItem.rating || null;
	};

	const handleDescription = () => {
		if (selectedItem && (activity || transportation))
			return selectedItem.description;

		if (amadeusCars) {
			const {
				category,
				type,
				transmission,
				miles,
				locationName,
				airportCode,
				terminalString,
				pickupTime,
				dropoffTime,
				doors,
				capacity,
				baggageAmount,
			} = sanitizeCarData(selectedItem.data);

			return (
				<>
					<div className='quick-info'>
						<div className='quick-info__section'>
							<img src={carDoorIcon} alt='car door' />
							<p>{doors}</p>
						</div>{' '}
						<div className='quick-info__section'>
							<img src={carSeatIcon} alt='car door' />
							<p>{capacity}</p>
						</div>
						<div className='quick-info__section'>
							<img src={baggageIcon} alt='car door' />
							<p>{baggageAmount}</p>
						</div>
					</div>
					<p>
						<span className='title'>Category: </span>
						{category}
					</p>

					<p>
						<span className='title'>Type: </span>
						{type}
					</p>

					<p>
						<span className='title'>Transmission: </span>
						{transmission}
					</p>

					{miles ? (
						<p>
							<span className='title'>Mileage: </span>
							{miles}
						</p>
					) : null}
					<p>
						<span className='title'>Pickup Time: </span>
						{pickupTime}
					</p>
					<p>
						<span className='title'>Dropoff Time: </span>
						{dropoffTime}
					</p>
					<p>
						<span className='title'>Pickup and Dropoff: </span>
						{locationName} ({airportCode}) {terminalString}
					</p>
				</>
			);
		}
		if (selectedItem && lodging) {
			const media =
				selectedItem &&
				selectedItem.data &&
				selectedItem.data.descriptive_info &&
				selectedItem.data.descriptive_info['HotelInfo'] &&
				selectedItem.data.descriptive_info['HotelInfo'][0] &&
				selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'] &&
				selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'][0][
					'MultimediaDescriptions'
				] &&
				selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'][0][
					'MultimediaDescriptions'
				][0] &&
				selectedItem.data.descriptive_info['HotelInfo'][0]['Descriptions'][0][
					'MultimediaDescriptions'
				][0]['MultimediaDescription'];

			const description =
				media &&
				media.find(
					(item) =>
						item['TextItems'] &&
						(item.info['AdditionalDetailCode'] === '2' ||
							item.info['AdditionalDetailCode'] === '1') &&
						item.info['InfoCode'] === '1'
				);

			const descriptionText =
				description &&
				description['TextItems'][0]['TextItem'][0]['Description'][0]._;

			return descriptionText && <>{descriptionText}</>;
		}
		return null;
	};

	const handleTitleDisplay = () => {
		if (selectedItem) {
			const { name, itineraries, data } = selectedItem;
			if (activity || transportation) return name;
			if (lodging) {
				let newName =
					data &&
					data.hotel_info &&
					data.hotel_info['info'] &&
					data.hotel_info['info']['HotelName']
						? data.hotel_info['info']['HotelName']
						: null;
				return newName;
			}

			if (amadeusCars) {
				const { category, type, carModel } = sanitizeCarData(selectedItem.data);

				return carModel ? `${carModel}` : `${category}/${type}`;
			}
			if (flight) {
				return `${itineraries[0].segments[0].departure.iataCode} - ${
					itineraries[0].segments[itineraries[0].segments.length - 1].arrival
						.iataCode
				}`;
			}
		}
	};

	const handleRemoveItem = () => {
		let cleanedEvent = selectedItem;
		if (activity || transportation) {
			cleanedEvent = {
				...cleanedEvent,
				start: null,
				end: null,
				inCart: false,
			};
		}
		removeItemFrom({ item: cleanedEvent, currentGroup, itemKey: key });
	};

	const handleClick = () => {
		if (itemSelected || itemInCart) {
			handleRemoveItem();
		} else if (!itemPaid || !itemAuthorized) {
			if (activity || transportation) {
				const { start, end } = selectedItem;
				if (!start || !end) {
					return null;
				}
			}
			addItemTo({ item: selectedItem, group: 'cart', itemKey: key });
		}
		deselectModalItem();
		setEventTime({
			start: null,
			end: null,
		});
	};

	const handleURL = () => {
		if (amadeusCars) {
			return selectedItem.data.carCompanyData.companyName;
		}
		if (selectedItem && lodging) {
			const url =
				selectedItem.data &&
				selectedItem.data.descriptive_info &&
				selectedItem.data.descriptive_info['ContactInfos'] &&
				selectedItem.data.descriptive_info['ContactInfos'][0] &&
				selectedItem.data.descriptive_info['ContactInfos'][0][
					'ContactInfo'
				][0] &&
				selectedItem.data.descriptive_info['ContactInfos'][0]['ContactInfo'][0][
					'URLs'
				] &&
				selectedItem.data.descriptive_info['ContactInfos'][0]['ContactInfo'][0][
					'URLs'
				][0]['URL'][0];

			return (
				url &&
				(url.slice(0, 6) === 'https:' || url.slice(0, 5) === 'http:') &&
				url
			);
		} else return 'https://' + selectedItem.url;
	};
	const handlePriceDisplay = () => {
		if (selectedItem) {
			const { type, price, data } = selectedItem;
			if (type === 'individual-activity' || type === 'public-transport') {
				return `${price.amount} ${price.currencyCode}/ Person`;
			}
			if (type === 'group-activity') {
				return `${price.amount} ${price.currencyCode}/ Group`;
			}
			if (type === 'private-transport') {
				return `${price.amount} ${price.currencyCode}/ Trip`;
			}
			if (data && lodging) {
				const { RoomRates } = returnItemFields(data, ['RoomRates']);
				const { Total, Rate } = returnItemFields(RoomRates, ['Total', 'Rate']);
				const { AmountAfterTax, converted_total } = returnItemFields(Total, [
					'AmountAfterTax',
					'converted_total',
				]);

				const total = converted_total || parseFloat(AmountAfterTax);

				const { days } = datetimeDifference(
					new Date(Rate[0].info['EffectiveDate']),
					new Date(
						Rate[Rate.length - 1]['Total']
							? Rate[Rate.length - 2].info.ExpireDate
							: Rate[Rate.length - 1].info.ExpireDate
					)
				);
				return {
					perNight: `${toDollarStyle(total / days, 'USD')}/ Night`,
					total: `${toDollarStyle(total, 'USD')}/ Stay`,
				};
			}

			if (data && flight) {
				const pricing_info =
					data && data.recommendation && data.recommendation.pricing_info;

				return `${toDollarStyle(pricing_info.total_amount, 'USD')}`;
			}
			if (amadeusCars) {
				const { perDay, total } = sanitizeCarData(selectedItem.data);

				return {
					perDay,
					total,
				};
			}
		}
	};

	const handleDuration = () =>
		transportation || activity ? selectedItem.timeLength : null;

	const handleDetails = () => {
		const price = handlePriceDisplay();

		return price ? (
			!flight ? (
				<Fragment>
					<ModalImage
						src={handleItemImage() || noImage}
						itemType={selectedItem.type || selectedItem.data.type}
					/>
					<div
						className={`content-container${
							itemPaid || itemAuthorized ? ' no-button' : ''
						}${
							(selectedItem.type && selectedItem.type === 'amadeus-car') ||
							(selectedItem.data?.type &&
								selectedItem.data.type === 'amadeus-car')
								? ' car'
								: ''
						}`}>
						<ItemDetails
							title={handleTitleDisplay()}
							url={handleURL()}
							rating={handleRating()}
							perNight={
								(lodging && price.perNight) || (amadeusCars && price.perDay)
							}
							price={
								lodging || amadeusCars
									? `${amadeusCars ? '$' : ''}${price.total}${
											amadeusCars ? '/rental' : ''
									  }`
									: price
							}
							description={handleDescription()}
							type={selectedItem.type || selectedItem.data.type}
							groupCapacity={
								selectedItem.groupCapacity && selectedItem.groupCapacity
							}
							hotelPickup={selectedItem.hotelPickup}
							duration={handleDuration()}
							customUrl={selectedItem.customUrl}
						/>
						<ItemOptions
							type={selectedItem.type || selectedItem.data.type}
							eventTime={eventTime}
							setEventTime={setEventTime}
						/>
					</div>
				</Fragment>
			) : (
				<div
					className='flight'
					style={{
						height: itemPaid || itemAuthorized ? '100%' : 'calc(100% - 7rem)',
					}}>
					{selectedItem.data.legs.map((itinerary, index) => {
						return (
							<FlightInfo
								key={`selected-item-leg-${index}`}
								price={selectedItem.data.recommendation.pricing_info}
								itineraryItem={itinerary}
								index={index}
							/>
						);
					})}
				</div>
			)
		) : null;
	};

	const returnButton = () => {
		return (
			!itemPaid &&
			!itemAuthorized && (
				<SelectedButton
					id={selectedItem._id}
					handleClick={handleClick}
					inItinerary={itemSelected || itemInCart}
				/>
			)
		);
	};

	const returnComponents = () => {
		return selectedItem ? (
			<Fragment>
				<button className='modal-close' onClick={deselectModalItem}>
					<i className='fa fa-times'></i>
				</button>
				{custom && <CustomEventForm deselectModalItem={deselectModalItem} />}
				{!custom && handleDetails()}
				{!custom && returnButton()}
			</Fragment>
		) : null;
	};

	return (
		itineraryModalOpen && (
			<motion.div
				key={`item-${selectedItem?._id}`}
				// variants={variants}
				initial={{ x: '100%', display: 'none' }}
				exit={{ x: '100%' }}
				animate={{
					x: '0%',
					display: 'block',
				}}
				transition={{ duration: 0.5 }}
				// transition={{ damping: 3000 }}
				className='newcss-item-modal'>
				{returnComponents()}
			</motion.div>
		)
	);
};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
	itineraryModalOpen: state.itineraryModal.itineraryModalOpen,
	trip: state.trip,
});

export default connect(mapStateToProps, {
	deselectModalItem,
	addItemTo,
	removeItemFrom,
})(ItineraryModal);
